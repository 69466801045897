<template>
  <v-container fluid style="padding: 0; margin: 0">
    <Menu
      style="position:fixed; height: 50px; z-index: 1; background: #dbc7ff"
      :client="true"
      :question="true"
      :nutri_info="true"
    />
    <div style="min-height: calc(100vh - 120px); padding-top: 50px;">
      <v-card color="#F4F9FF" class="mb-3" style="min-height: inherit">
        <v-container
          fluid
          style="height: 100%; margin:0px; padding:0px; min-height: inherit"
          class="d-flex flex-row"
        >
          <div class="list-body">
            <v-card-title
              class="d-flex flex-row"
              style="justify-content: space-between"
            >
              <label class="item_title">Clients</label>
              <div class="d-flex flex-row">
                <button-download-client-data />
                <v-btn
                  color="#7024C4"
                  dark
                  class="mt-2 ml-3 d-none"
                  @click="confirmDialog = true"
                  small
                >
                  <v-icon class="mr-2">mdi-database-sync-outline</v-icon>
                  Sync with OneCiti
                </v-btn>
                <v-btn
                  color="#7024C4"
                  dark
                  class="mt-2 ml-3"
                  @click="onShowFilter"
                  small
                  :outlined="filterSection"
                >
                  <v-icon class="mr-2">mdi-account-filter-outline</v-icon>
                  Filter
                </v-btn>
              </div>
            </v-card-title>
            <div
              v-if="
                filterSection ||
                  max_age ||
                  min_age ||
                  gender ||
                  languages.length ||
                  situations.length ||
                  maritals.length ||
                  keyword ||
                  isNew
              "
              class="pa-2"
            >
              <div class="d-flex">
                <span class="mt-2" style="min-width: 90px;">Filtered by:</span>
                <div class="d-flex align-center" style="flex-wrap: wrap;">
                  <v-chip
                    class="ma-2"
                    close
                    @click:close="clearKeyword"
                    v-if="keyword"
                  >
                    {{ keyword }}
                  </v-chip>
                  <v-chip
                    class="ma-2"
                    close
                    @click:close="isNew = false"
                    v-if="isNew"
                  >
                    New
                  </v-chip>
                  <v-chip
                    class="ma-2"
                    close
                    @click:close="onRemoveAge"
                    v-if="filterOptions.max_age || filterOptions.min_age"
                  >
                    <span class="mr-1">Age</span>
                    <span v-if="filterOptions.max_age && filterOptions.min_age">
                      {{ filterOptions.min_age }}-{{ filterOptions.max_age }}
                    </span>
                    <span v-else-if="filterOptions.max_age"
                      >&#60;{{ filterOptions.max_age }}</span
                    >
                    <span v-else>&#62;{{ filterOptions.min_age }}</span>
                  </v-chip>
                  <v-chip
                    class="ma-2"
                    close
                    @click:close="onRemoveGender"
                    v-if="
                      filterOptions.gender && filterOptions.gender != 'Both'
                    "
                  >
                    {{ filterOptions.gender }}
                  </v-chip>
                  <div v-for="language in languages" :key="language">
                    <v-chip
                      class="ma-2"
                      close
                      @click:close="onRemoveLanguage(language)"
                      v-if="languages"
                    >
                      {{ language == "Other" ? "Other language" : language }}
                    </v-chip>
                  </div>
                  <div v-for="situation in situations" :key="situation">
                    <v-chip
                      class="ma-2"
                      close
                      @click:close="onRemoveSituation(situation)"
                    >
                      {{ situation }}
                    </v-chip>
                  </div>
                  <div v-for="marital in maritals" :key="marital">
                    <v-chip
                      class="ma-2"
                      close
                      @click:close="onRemoveMarital(marital)"
                    >
                      {{ marital }}
                    </v-chip>
                  </div>
                </div>
              </div>
              <div
                class="d-flex flex-row"
                v-if="filterSection"
                style="flex-wrap: wrap"
              >
                <div class="pa-4">
                  <div class="filter-title">Age</div>
                  <div class="d-flex align-center">
                    <v-text-field
                      outlined
                      dense
                      style="max-width: 80px"
                      v-model="min_age"
                      @input="onChangeFilter"
                      @keypress.enter="onChangeFilter"
                      type="number"
                    />
                    <span class="ml-3">-</span>
                    <v-text-field
                      outlined
                      dense
                      style="max-width: 80px"
                      class="ml-3"
                      v-model="max_age"
                      @input="onChangeFilter"
                      @keypress.enter="onChangeFilter"
                      type="number"
                    />
                  </div>
                </div>
                <div class="pa-4">
                  <div class="filter-title">Gender</div>
                  <div>
                    <v-select
                      :items="genderOptions"
                      outlined
                      dense
                      v-model="gender"
                      style="width: 120px"
                      @input="onChangeFilter"
                    />
                  </div>
                </div>
                <div class="pa-4">
                  <div class="filter-title">Language</div>
                  <div>
                    <v-select
                      :items="languageOptions"
                      outlined
                      dense
                      multiple
                      v-model="languages"
                      @input="onChangeFilter"
                    />
                  </div>
                </div>
                <div class="pa-4">
                  <div class="filter-title">Situation</div>
                  <div>
                    <v-select
                      :items="situationOptions"
                      outlined
                      dense
                      v-model="situations"
                      multiple
                      @input="onChangeFilter"
                    />
                  </div>
                </div>
                <div class="pa-4">
                  <div class="filter-title">Marital status</div>
                  <div>
                    <v-select
                      :items="maritalOptions"
                      outlined
                      dense
                      v-model="maritals"
                      multiple
                      @input="onChangeFilter"
                    />
                  </div>
                </div>
                <div class="pa-4">
                  <div class="filter-title">New clients</div>
                  <div>
                    <v-switch
                      v-model="isNew"
                      @change="onChangeFilter"
                      class="mt-0"
                    />
                  </div>
                </div>
              </div>
              <div
                v-if="summary && (summary.family || summary.people)"
                class="my-3"
              >
                <div>
                  <span style="color: #7024c4; font-family: 'Poppins-SemiBold';"
                    >Matched family:</span
                  >
                  <span style="font-weight: 500; margin-left: 5px">
                    {{ summary.family }}
                  </span>
                </div>
                <div>
                  <span style="color: #7024c4; font-family: 'Poppins-SemiBold';"
                    >Matched people:</span
                  >
                  <span style="font-weight: 500; margin-left: 5px">
                    {{ summary.people }}
                  </span>
                </div>
              </div>
            </div>
            <v-data-table
              :headers="headers"
              :items="clients"
              :items-per-page="15"
              :loading="loading"
              @click:row="onSelectClient"
              @dblclick:row="onDoubleClicked"
              :item-class="clientsClass"
              :custom-sort="customSort"
              :search="search"
              :custom-filter="customFilter"
              show-expand
              :expanded.sync="expanded"
              item-key="_id"
            >
              <template v-slot:[`item.image`]="{ item }">
                <v-avatar class="elevation-6 my-3 ml-5">
                  <v-img :src="item.image ? item.image : placeholderImage" />
                </v-avatar>
              </template>
              <template v-slot:[`item.info`]="{ item }">
                <div
                  v-html="
                    getFiltered(
                      (item.first_name ? item.first_name + ' ' : '') +
                        (item.last_name ? item.last_name : '')
                    )
                  "
                  class="mt-3"
                  style="font-size: 14px; color:#482684; font-weight: 500;"
                />
                <div
                  v-html="getFiltered(formatPhoneNumber(item.phone))"
                  class="mt-1"
                  style="font-size: 14px; color:#482684"
                />
                <div
                  v-html="getFiltered(item.email)"
                  class="mt-1 mb-3"
                  style="font-size: 14px; color:#482684; font-weight: 400;"
                />
              </template>
              <template v-slot:[`item.client_id`]="{ item }">
                <div
                  v-html="getFiltered(item.client_id)"
                  style="font-weight: 600; color: #482684"
                />
              </template>
              <template v-slot:[`item.address`]="{ item }">
                <div
                  v-html="
                    getFiltered(
                      (item.address ? item.address : 'N/A') +
                        (item.address2 ? ', ' + item.address2 : '')
                    )
                  "
                  style="color: #482684; font-size: 14px;"
                />
              </template>
              <template v-slot:[`item.rest_balance`]="{ item }">
                <div class="my-3">
                  <!-- <div v-if="item.rest_balance && item.rest_balance.meals">
                    <span style="color:#400D9B;font-size: 12px;" class="mr-3"
                      >Meals:
                    </span>
                    <span
                      v-text="
                        item.rest_balance && item.rest_balance.meals
                          ? item.rest_balance.meals
                          : 0
                      "
                      style="color:#7024C4;font-size: 12px;"
                    />
                  </div> -->
                  <div v-if="item.rest_balance && item.rest_balance.fruits">
                    <span style="color:#400D9B;font-size: 12px;" class="mr-3"
                      >Fruits:
                    </span>
                    <span
                      v-text="
                        item.rest_balance && item.rest_balance.fruits
                          ? item.rest_balance.fruits
                          : 0
                      "
                      style="color:#7024C4;font-size: 12px;"
                    />
                  </div>
                  <div v-if="item.rest_balance && item.rest_balance.vegetables">
                    <span style="color:#400D9B;font-size: 12px;" class="mr-3"
                      >Vegetables:
                    </span>
                    <span
                      v-text="
                        item.rest_balance && item.rest_balance.vegetables
                          ? item.rest_balance.vegetables
                          : 0
                      "
                      style="color:#7024C4;font-size: 12px;"
                    />
                  </div>
                  <div v-if="item.rest_balance && item.rest_balance.grains">
                    <span style="color:#400D9B;font-size: 12px;" class="mr-3"
                      >Grains:
                    </span>
                    <span
                      v-text="
                        item.rest_balance && item.rest_balance.grains
                          ? item.rest_balance.grains
                          : 0
                      "
                      style="color:#7024C4;font-size: 12px;"
                    />
                  </div>
                  <div v-if="item.rest_balance && item.rest_balance.protein">
                    <span style="color:#400D9B;font-size: 12px;" class="mr-3"
                      >Protein:
                    </span>
                    <span
                      v-text="
                        item.rest_balance && item.rest_balance.protein
                          ? item.rest_balance.protein
                          : 0
                      "
                      style="color:#7024C4;font-size: 12px;"
                    />
                  </div>
                  <div v-if="item.rest_balance && item.rest_balance.dairy">
                    <span style="color:#400D9B;font-size: 12px;" class="mr-3"
                      >Dairy:
                    </span>
                    <span
                      v-text="
                        item.rest_balance && item.rest_balance.dairy
                          ? item.rest_balance.dairy
                          : 0
                      "
                      style="color:#7024C4;font-size: 12px;"
                    />
                  </div>
                </div>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <div
                  v-html="getFiltered(item.status)"
                  style="font-weight: 400; color: #482684; font-size: 14px;"
                />
              </template>
              <template v-slot:[`item.expired`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      v-html="getExpired(item.intake_date)"
                      v-on="on"
                      v-bind="attrs"
                      style="font-weight: 400; color: #482684; font-size: 14px;"
                    />
                  </template>
                  {{
                    getExpired(item.intake_date) == "Yes"
                      ? "Expired"
                      : "Not expired"
                  }}
                </v-tooltip>
              </template>
              <template v-slot:[`item.qualified`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      v-html="getFiltered(item.qualified ? 'Yes' : 'No')"
                      v-on="on"
                      v-bind="attrs"
                      style="font-weight: 400; color: #482684; font-size: 14px;"
                    />
                  </template>
                  {{ item.qualified ? "Qualified" : "Not qualified" }}
                </v-tooltip>
              </template>
              <template v-slot:expanded-item="{ item }">
                <td :colspan="headers.length" class="px-0">
                  <v-data-table
                    :headers="subHeaders"
                    :items="familyData[item.client_id]"
                    hide-default-footer
                    :loading="loadingFamily[item.client_id]"
                    class="px-3"
                  >
                    <template v-slot:[`item.name`]="{ item }">
                      <div
                        v-html="getFiltered(item.name)"
                        :class="filterClass('name', item)"
                      />
                    </template>
                    <template v-slot:[`item.gender`]="{ item }">
                      <div
                        v-html="getFiltered(item.gender)"
                        :class="filterClass('gender', item)"
                      />
                    </template>
                    <template v-slot:[`item.age`]="{ item }">
                      <div>
                        <div
                          v-html="
                            getFiltered(
                              `${
                                item.age == 0 ? '< 1' : item.age ? item.age : ''
                              }`
                            )
                          "
                          :class="filterClass('age', item)"
                        />
                      </div>
                    </template>
                    <template v-slot:[`item.relation`]="{ item }">
                      <div v-html="getFiltered(item.relation)" />
                    </template>
                    <template v-slot:[`item.situation`]="{ item }">
                      <div
                        v-html="getFiltered(item.situation)"
                        :class="filterClass('situation', item)"
                      />
                    </template>
                    <template v-slot:[`item.schoolName`]="{ item }">
                      <div v-html="getFiltered(item.schoolName)" />
                    </template>
                  </v-data-table>
                </td>
              </template>
            </v-data-table>
          </div>
          <client-info-card
            :id="profile._id"
            :onIntake="onIntake"
            :onDeleted="onDeletedClient"
            :onClosePanel="onClosePanel"
            v-if="profile && profile._id"
            style="top: 120px"
          />
        </v-container>
      </v-card>
    </div>
    <v-dialog v-model="confirmDialog" max-width="520px">
      <v-card>
        <v-card-title class="headline justify-center">
          Sync database with OneCiti?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="confirmDialog = !confirmDialog"
          >
            Cancel
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="confirmSyncAction"
            :loading="loading"
          >
            OK
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar">
      {{ snackMessage }}
    </v-snackbar>
  </v-container>
</template>
<script>
import Menu from "@/components/core/Menu.vue";
import { mapActions, mapGetters } from "vuex";
import ButtonDownloadClientData from "@/components/client/ButtonDownloadClientData.vue";
import ClientInfoCard from "@/components/client/ClientInfoCard.vue";
export default {
  components: {
    Menu,
    ButtonDownloadClientData,
    ClientInfoCard,
  },
  data() {
    return {
      isSelectingPhoto: false,
      loading: false,
      snackbar: false,
      errorMessage: null,
      snackMessage: null,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => (v && v.length >= 8) || "Min 8 characters",
        passwordMatch: () =>
          this.profile.password != this.profile.confirmPassword
            ? `Password doesn't match`
            : "",
      },
      showPassword: false,
      headers: [
        { text: "", value: "image", sortable: false },
        { text: "ClientId", value: "client_id" },
        { text: "Info", value: "info" },
        { text: "Address", value: "address" },
        // { text: "Eligible", value: "rest_balance" },
        // { text: "Status", value: "status" },
        // { text: "Qualified", value: "qualified" },
        // { text: "Expired", value: "expired" },
        { text: "", value: "data-table-expand" },
      ],
      subHeaders: [
        { text: "Name", value: "name" },
        { text: "Gender", value: "gender" },
        { text: "Age", value: "age" },
        { text: "Relation", value: "relation" },
        { text: "Situation", value: "situation" },
        { text: "School Name", value: "schoolName" },
      ],
      selected: -1,
      placeholderImage: require("@/assets/default.jpg"),
      profile: { email: "" },
      selectedFile: null,
      deleteDialog: false,
      confirmDialog: false,
      statusOptions: [
        "No",
        "Yes",
        "Urgent Help",
        "Intake Completed",
        "No response",
      ],
      search: "",
      clients: [],
      expanded: [],
      familyData: {},
      loadingFamily: [],
      filteredFamily: {},
      countData: {},
      summary: {},
      filterSection: false,
      filterOptions: {},
      genderOptions: ["Male", "Female", ""],
      languageOptions: ["English", "Portuguese", "Spanish", "Russian", "Other"],
      situationOptions: [],
      maritalOptions: [],
      min_age: "",
      max_age: "",
      gender: "",
      languages: [],
      situations: [],
      maritals: [],
      isNew: false,
    };
  },
  computed: {
    ...mapGetters({
      allClients: "client/getAllClients",
      keyword: "auth/getKeyword",
    }),
  },
  methods: {
    ...mapActions({
      fetchAllClients: "client/fetchAllClients",
      syncWithOneCiti: "client/syncWithOneCiti",
      editClient: "client/editClient",
      deleteClient: "client/deleteClient",
      setBalance: "client/setBalance",
      clearKeyword: "auth/clearKeyword",
      fetchFamilyIntakes: "intake/fetchFamilyIntakes",
      getFamilyFiltered: "intake/getFamilyFiltered",
      getValues: "variable/getValues",
    }),
    onImageSelected(file) {
      this.selectedFile = file;
    },
    onChange() {
      this.errorMessage = null;
    },
    onSelectClient(item) {
      this.selected = this.clients.indexOf(item);
    },
    getAddressData(addressData) {
      this.profile.address = addressData.name;
      this.profile.state = addressData.administrative_area_level_1;
      this.profile.city = addressData.locality;
      this.profile.zip = addressData.postal_code;
      this.profile.location =
        addressData.latitude + "," + addressData.longitude;
      this.$refs.address.update(this.profile.address);
    },
    onIntake() {
      this.$router.push({
        name: "intakeView",
        params: { id: this.profile._id },
      });
    },
    onDoubleClicked(event, { item }) {
      this.selected = this.clients.indexOf(item);
      this.$router.push({
        name: "intakeView",
        params: { id: this.profile._id },
      });
    },
    showErrorMessage(message) {
      this.snackMessage = message;
      this.snackbar = true;
    },
    clientsClass(item) {
      if (this.profile && item._id == this.profile._id) {
        return "selected";
      }
      return "";
    },
    confirmSyncAction() {
      this.confirmDialog = false;
      this.loading = true;
      this.syncWithOneCiti()
        .then(() => {
          this.loading = false;
          this.snackbar = true;
          this.snackMessage = "Sync database is done successfully";
        })
        .catch((error) => {
          this.loading = false;
          if (error.response != undefined && error.response.status === 401) {
            this.tokenDialog = true;
          } else {
            console.log(error);
          }
        });
    },
    formatPhoneNumber(str) {
      let cleaned = ("" + str).replace(/\D/g, "");
      let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        let intlCode = match[1] ? "+1 " : "";
        return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join(
          ""
        );
      }
      return str;
    },
    customSort(items, index, isDesc) {
      if (index.length == 0 || index[0] == "address") {
        items.sort((a, b) => {
          if (!a.address && !b.address) return 0;
          if (!a.address) return 1;
          if (!b.address) return -1;
          var aFirstIntStr = a.address
            ? a.address.replace(/(^\d+)(.+$)/i, "$1")
            : "";
          var bFirstIntStr = b.address
            ? b.address.replace(/(^\d+)(.+$)/i, "$1")
            : "";
          var aInt = 0;
          var bInt = 0;
          if (aFirstIntStr) {
            aInt = parseInt(aFirstIntStr);
            if (isNaN(aInt)) aInt = 0;
          }
          if (bFirstIntStr) {
            bInt = parseInt(bFirstIntStr);
            if (isNaN(bInt)) bInt = 0;
          }
          if (aInt != bInt) return aInt - bInt;
          var aAddress = `${a.address ? a.address : ""}${
            a.address && a.address2 ? ", " : ""
          }${a.address2 ? a.address2 : ""}`;
          var bAddress = `${b.address ? b.address : ""}${
            b.address && b.address2 ? ", " : ""
          }${b.address2 ? b.address2 : ""}`;
          if (aAddress < bAddress) return -1;
          if (aAddress > bAddress) return 1;
          return 0;
        });
      } else if (index[0] === "info") {
        items.sort((a, b) => {
          var order = 0;
          var aStr = `${a["first_name"]} ${a["last_name"]}`;
          var bStr = `${b["first_name"]} ${b["last_name"]}`;

          if (aStr < bStr) order = -1;
          else if (aStr > bStr) order = 1;
          if (isDesc[0]) order *= -1;
          return order;
        });
      } else if (index[0] === "expired") {
        items.sort((a, b) => {
          var order = 0;
          var aDate = this.getExpired(a.intake_date);
          var bDate = this.getExpired(b.intake_date);
          if (!aDate && !bDate) return order;
          if (aDate == bDate) return order;
          if (!aDate && bDate == "No") return -1;
          if (!aDate && bDate == "Yes") return 1;
          if (!bDate && aDate == "No") return 1;
          if (!bDate && aDate == "Yes") return -1;

          if (aDate == "Yes" && bDate == "No") order = -1;
          else order = 1;
          return order;
        });
      } else {
        items.sort((a, b) => {
          var order = 0;
          if (a[index] < b[index]) order = -1;
          else if (a[index] > b[index]) order = 1;
          if (isDesc[0]) order *= -1;
          return order;
        });
      }
      return items;
    },
    getFiltered(words) {
      if (!words) return "";
      var filterd = this.$options.filters.highlight(words, this.keyword);
      return filterd;
    },
    customFilter(value, search, item) {
      var searchFilter = false;
      const key = this.onGetKey();
      if (
        this.keyword &&
        !this.min_age &&
        !this.max_age &&
        !this.gender &&
        !this.situations.length
      ) {
        if (
          (`${item.first_name} ${item.last_name}` &&
            `${item.first_name} ${item.last_name}`
              .toUpperCase()
              .includes(this.keyword.toUpperCase())) ||
          (item.phone &&
            item.phone.toUpperCase().includes(this.keyword.toUpperCase())) ||
          (item.phone &&
            this.formatPhoneNumber(item.phone)
              .toUpperCase()
              .includes(this.keyword.toUpperCase())) ||
          (item.address &&
            item.address.toUpperCase().includes(this.keyword.toUpperCase())) ||
          (item.address2 &&
            item.address2.toUpperCase().includes(this.keyword.toUpperCase())) ||
          (item.address &&
            item.address2 &&
            `${item.address}, ${item.address2}`
              .toUpperCase()
              .includes(this.keyword.toUpperCase())) ||
          (item.status &&
            item.status.toUpperCase().includes(this.keyword.toUpperCase())) ||
          (item.email &&
            item.email.toUpperCase().includes(this.keyword.toUpperCase())) ||
          (item.status &&
            item.status.toUpperCase().includes(this.keyword.toUpperCase())) ||
          (item.client_id &&
            `${item.client_id}`
              .toUpperCase()
              .includes(this.keyword.toUpperCase()))
        ) {
          return true;
        }
      } else if (key == "{}") {
        return true;
      }

      if (
        this.filteredFamily[key] != undefined &&
        this.filteredFamily[key].length != 0
      ) {
        if (this.filteredFamily[key].includes(item._id)) {
          return true;
        } else return false;
      }
      return searchFilter;
    },
    onDeletedClient() {
      this.clearKeyword();
      if (this.selected) this.selected--;
    },
    getExpired(dateStr) {
      if (!dateStr) return "";
      var today = new Date();
      var date = new Date(dateStr);
      const diffTime = Math.abs(today - date);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays >= 90) return "Yes";
      else return "No";
    },
    getFamilyData(client_id) {
      this.loadingFamily[client_id] = true;
      this.fetchFamilyIntakes({ client_id })
        .then((res) => {
          this.loadingFamily[client_id] = false;
          this.familyData[client_id] = res;
          this.familyData = { ...this.familyData };
        })
        .catch((error) => {
          this.loadingFamily[client_id] = false;
          console.log(error.response.data.message);
        });
    },
    onClosePanel() {
      this.profile = {};
      this.selected = -1;
    },
    onShowFilter() {
      this.filterSection = !this.filterSection;
    },
    onChangeFilter() {
      this.filterOptions = {
        ...this.filterOptions,
        max_age: this.max_age,
        min_age: this.min_age,
        gender: this.gender,
        language: this.languages.join(),
        situation: this.situations.join(),
        marital: this.maritals.join(),
      };
      this.onSearchFamily();
    },
    onRemoveAge() {
      this.max_age = null;
      this.min_age = null;
      this.onChangeFilter();
    },
    onRemoveGender() {
      this.gender = null;
      this.onChangeFilter();
    },
    onRemoveLanguage(language) {
      this.languages = this.languages.filter((l) => l != language);
      this.onChangeFilter();
    },
    onRemoveSituation(situation) {
      this.situations = this.situations.filter((s) => s != situation);
      this.onChangeFilter();
    },
    onRemoveMarital(marital) {
      this.maritals = this.maritals.filter((s) => s != marital);
      this.onChangeFilter();
    },
    onUpdateSummary() {
      var key = this.onGetKey();
      this.summary = this.countData[key];
    },
    onSearchFamily() {
      var key = this.onGetKey();
      this.search = key;
      if (key == "{}") return;
      var params = {
        ...JSON.parse(key),
      };
      if (!this.filteredFamily[key]) {
        this.getFamilyFiltered(params)
          .then((res) => {
            var sum = 0;
            this.filteredFamily[key] = res.map((r) => {
              return r._id;
            });
            for (var i = 0; i < res.length; i++) {
              sum += res[i].count;
            }
            this.countData[key] = { family: res.length, people: sum };
            this.onUpdateSummary();
            this.search = "update" + key;
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.data.message);
            } else {
              console.log(error);
            }
          });
      } else {
        this.summary = this.countData[key];
      }
    },
    onGetKey() {
      var obj = {};
      if (this.keyword) obj.key = this.keyword;
      if (this.min_age) obj.min_age = this.min_age;
      if (this.max_age) obj.max_age = this.max_age;
      if (this.gender && this.gender != "Both") obj.gender = this.gender;
      if (this.languages && this.languages.length) {
        this.languages = this.languages.sort();
        obj.language = this.languages.join();
      }
      if (this.situations && this.situations.length) {
        this.situations = this.situations.sort();
        obj.situation = this.situations.join();
      }
      if (this.maritals && this.maritals.length) {
        this.maritals = this.maritals.sort();
        obj.marital = this.maritals.join();
      }
      if (this.isNew) obj.is_new = true;
      return JSON.stringify(obj);
    },
    filterClass(key, item) {
      const value = item[key];
      if (value == undefined) return "";
      var res = "";

      if (this.min_age && this.max_age) {
        if (
          this.min_age <= parseInt(`${item.age}`) &&
          this.max_age >= parseInt(`${item.age}`)
        ) {
          if (key == "age") res = "filteredClass";
        } else return "";
      } else if (this.min_age) {
        if (this.min_age <= parseInt(`${item.age}`)) {
          if (key == "age") res = "filteredClass";
        } else return "";
      } else if (this.max_age) {
        if (this.max_age >= parseInt(`${item.age}`)) {
          if (key == "age") res = "filteredClass";
        } else return "";
      }

      if (this.gender && this.gender != "Both") {
        if (this.gender == item.gender) {
          if (key == "gender") res = "filteredClass";
        } else return "";
      }

      if (this.situations && this.situations.length) {
        if (this.situations.includes(item.situation)) {
          if (key == "situation") res = "filteredClass";
        } else return "";
      }

      return res;
    },
  },
  created() {
    this.loading = true;
    this.fetchAllClients()
      .then(() => {
        this.loading = false;
        this.selected = -1;
        this.onSearchFamily();
      })
      .catch((error) => {
        this.loading = false;
        if (error.response != undefined && error.response.status === 401) {
          this.tokenDialog = true;
        } else {
          console.log(error.response.data.message);
        }
      });
    this.getValues({ title: "Situation Types" })
      .then((res) => {
        this.situationOptions = res.map((v) => v.text).sort();
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
    this.getValues({ title: "Marital Types" })
      .then((res) => {
        this.maritalOptions = res.map((v) => v.text).sort();
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
    this.filterOptions = { ...this.$route.query };
    this.max_age = this.filterOptions.max_age;
    this.min_age = this.filterOptions.min_age;
    this.gender = this.filterOptions.gender;
    this.isNew =
      this.filterOptions.new == "true" || this.filterOptions.new == true;
    if (this.filterOptions.language)
      this.languages = this.filterOptions.language.split(",");
    if (this.filterOptions.situation)
      this.situations = this.filterOptions.situation.split(",");
    if (this.filterOptions.marital)
      this.maritals = this.filterOptions.marital.split(",");
  },
  watch: {
    selected(newValue) {
      if (newValue != -1 && this.clients.length > newValue) {
        this.profile = this.clients[newValue];
      }
    },
    keyword(newValue) {
      this.search = newValue;
      if (newValue) {
        this.onSearchFamily();
      }
    },
    allClients(newValue) {
      console.log(newValue);
      this.clients = [...newValue];
    },
    isNew(newValue) {
      if (
        (this.$route.query.new == "true" || this.$route.query.new == true) !=
        newValue
      ) {
        this.$router.replace({
          query: { ...this.$route.query, new: newValue ? "true" : "false" },
        });
      }
    },
    expanded(newValue) {
      for (var i = 0; i < newValue.length; i++) {
        if (!this.familyData[newValue[i].client_id]) {
          this.getFamilyData(newValue[i].client_id);
        }
      }
    },
    filterOptions(newValue) {
      this.search = this.onGetKey();
      if (
        (newValue || Object.keys(newValue).length) &&
        Object.getPrototypeOf(newValue) === Object.prototype
      ) {
        if (!this.$route.query) {
          this.$router.replace({
            query: { ...newValue },
          });
        } else {
          newValue = { ...this.$route.query, ...newValue };
          if (JSON.stringify(newValue) != JSON.stringify(this.$route.query)) {
            this.$router.replace({
              query: { ...newValue },
            });
          }
        }
      } else if (
        this.$router.query &&
        Object.keys(this.$router.query).length === 0 &&
        Object.getPrototypeOf(this.$router.query) === Object.prototype
      ) {
        this.$router.replace({
          query: {},
        });
      }
    },
  },
};
</script>
<style scoped>
.filter-title {
  font-family: "Poppins-SemiBold";
  color: #472583;
  margin-bottom: 5px;
}
.filteredClass {
  background: lightyellow;
  width: fit-content;
}
.list-body {
  width: 100%;
  min-width: 200px;
  margin-left: 60px;
  margin-right: 20px;
  align-items: center;
  margin-bottom: 30px;
}
.item_title {
  color: #6e3dc6;
  font-family: "Poppins-Bold";
  font-size: 25px;
  padding-left: 5px;
  padding-top: 10px;
}
.item_description {
  color: #482684;
  /* font-family: 'Poppins-Bold'; */
  font-size: 18px;
  padding-left: 7px;
}
.client_title {
  color: #747474;
  font-family: "Poppins-SemiBold";
  font-size: 16px;
}
.users_image_section {
  padding-bottom: 20px;
  padding-top: 30px;
  padding-left: 40px;
  justify-content: center;
  max-width: 200px;
}
.edit_button {
  position: absolute;
  bottom: 0px;
  right: 0px;
}
.profile {
  overflow: visible;
}
.request_description_title {
  color: #7024c4;
  font-family: "Poppins-SemiBold";
  font-size: 15px;
}
.request_description_item {
  color: #482684;
  font-family: "Poppins-SemiBold";
  font-size: 12px;
}
/* .v-text-field >>> input {
    font-size: 10px;
  } */
.v-text-field >>> .v-text-field__details {
  display: none;
}

/* Track */
.right-section::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.right-section::-webkit-scrollbar-thumb {
  background: rgba(240, 160, 0, 0.3);
  border-radius: 3px;
}

/* Handle on hover */
.right-section::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}
.v-select >>> input {
  display: none !important;
  background: red;
}
.v-text-field >>> input#map::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: transparent;
}
.v-text-field >>> input#map::-moz-placeholder {
  /* Firefox 19+ */
  color: transparent;
}
.v-text-field >>> input#map:-ms-input-placeholder {
  /* IE 10+ */
  color: transparent;
}
.v-text-field >>> input#map:-moz-placeholder {
  /* Firefox 18- */
  color: transparent;
}
.v-text-field >>> input#map::placeholder {
  color: transparent;
}
.v-data-table >>> .selected {
  background-color: #fff4e0 !important;
}
</style>
