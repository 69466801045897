<template>
  <div>
    <download-excel
      class="btn btn-default"
      :fields="json_fields"
      :fetch="fetchData"
      :before-generate="startDownload"
      :before-finish="finishDownload"
      worksheet="Healthy Communities Clients Data"
      name="HC Clients.xls"
    >
      <v-btn
        color="#7024C4"
        dark
        class="mt-2"
        :loading="loading"
        small
        outlined
      >
        <v-icon class="mr-2" size="16">mdi-table-arrow-down</v-icon>
        Download Excel
      </v-btn>
    </download-excel>
    <v-snackbar v-model="snackbar">
      {{ snackMessage }}
    </v-snackbar>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      json_fields: {
        "Client Id": "client_id",
        "First Name": "first_name",
        "Last Name": "last_name",
        Email: "email",
        Phone: "phone",
        Balance: "balance",
        Address: "address",
        "Address 2": "address2",
        City: "city",
        Status: "status",
        "Other Info": "other_info",
        "Intake Result": "intake_result",
        "Intake Date": "intake_date",
        "Intake Details": "intake",
      },
      loading: false,
      snackMessage: null,
      snackbar: false,
    };
  },
  methods: {
    ...mapActions({
      getExcelJsonData: "client/getExcelJsonData",
    }),
    async fetchData() {
      try {
        var res = await this.getExcelJsonData();
        if (res.success) return res.res;
        else {
          this.showSnackbar(res.message);
          this.finishDownload();
        }
      } catch (e) {
        this.showSnackbar(e.message);
        this.finishDownload();
      }
    },
    startDownload() {
      this.loading = true;
    },
    finishDownload() {
      this.loading = false;
    },
    showSnackbar(str) {
      this.snackbar = true;
      this.snackMessage = `${str}`;
    },
  },
};
</script>
